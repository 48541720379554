@import "@sx-core/src/scss/element/article.scss";

.bg-coloured {
    padding: $modulspacing-desktop-m 0;
    color: $color-white;
    background-color: $color-primary-800;

    & > *:first-child {
        margin-top: initial;
    }

    & > *:last-child {
        padding-bottom: initial;
        margin-bottom: initial;
    }

    h2.headline, p.headline.headline--topline {
        color: $color-white;
    }

    p {
        color: $color-primary-500;
    }
}

.slider + .bg-coloured, .list + .bg-coloured {
    padding-top: initial;
}

.article {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;
    text-align: center;
}

.headline {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/H1/Bold/Default');
    margin-bottom: pxToRem(36);
}

.headline.headline--title {
    @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
    margin-bottom: pxToRem(20);
}

.headline.headline--topline {
    color: $color-primary-1200;
    @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
}

@include media-breakpoint-down(md) {
    .bg-coloured {
        padding: $modulspacing-mobile-m 0;
    }

    .slider + .bg-coloured, .list + .bg-coloured {
        padding-top: initial;
    }

    .article {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;

        &__inner {
            padding: 0 pxToRem(8);
        }
    }
}

/*** || CTA ***/

.cta {
    justify-content: center;
    margin-top: pxToRem(48);
}

/*** || Preis ***/

.price {
    margin-top: pxToRem(36);
    @include getFigmaStyle('Desktop/SM/Regular/Uppercase');

    &__inner {
        display: inline-flex;
        align-items: center;
        column-gap: pxToRem(16);
    }

    &__price {
        @include getFigmaStyle('Desktop/H3/Bold/Default');
    }
}

/*** || Room ***/

.view-room .article {
    margin-bottom: $modulspacing-desktop-s;
}

/*** || Storno ***/

.article-storno {
    position: relative;
    padding: pxToRem(28);

    .image {
        border-radius: $border-radius;
        overflow: hidden;
    }

    h2, p {
        color: $color-primary-1200;
    }

    &__inner {
        position: absolute;
        top: pxToRem(76);
        left: pxToRem(76);
        z-index: 10;
        padding: pxToRem(36);
        background-color: $color-secondary-100;
        border-radius: $border-radius;

        .headline {
            @include getFigmaStyle('Desktop/H4/Bold/Default');
            margin-bottom: pxToRem(20);
        }
    }

    .storno__body {
        padding: pxToRem(36);
        background-color: $color-secondary-800;
    }

    .cta {
        justify-content: flex-start;
        margin-top: pxToRem(20);
    }
}

@include media-breakpoint-down(lg) {
    .article-storno {
        &__inner {
            top: pxToRem(44);
            left: pxToRem(44);
        }
    }
}

@include media-breakpoint-down(md) {
    .article-storno {
        padding: pxToRem(16);

        .image {
            border-radius: $border-radius $border-radius 0 0;
        }

        &__inner {
            position: relative;
            top: initial;
            left: initial;
            padding: pxToRem(28) pxToRem(16);
            border-radius: 0 0 $border-radius $border-radius;

            .headline {
                text-align: center;
                margin-bottom: pxToRem(20);
            }

            .content {
                text-align: center;
            }
        }

        .cta {
            justify-content: center;
        }
    }
}

/*** Logo Content ***/

.logo-content__outer {
    display: flex;
    justify-content: center;
    margin-top: pxToRem(56);
}

.logo--content {
    max-width: 300px;
    margin-left: pxToRem(60);

    img {
        width: 100%;
        height: auto;
    }
}

@include media-breakpoint-down(md) {
    .logo-content__outer {
        margin-top: pxToRem(36);
    }

    .logo--content {
        max-width: 250px;
    }
}