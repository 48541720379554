@import "@sx-core/src/scss/element/keyvisual.scss";

.keyvisual {
    padding: pxToRem(28);
    padding-top: initial;
    margin-top: pxToRem(144);
    border-radius: $border-radius;
    overflow: initial;

    .keyvisual {
        padding: initial;
        margin-top: initial;
        overflow: hidden;
    }

    .splide__arrows {
        display: none;
    }
}

.keyvisual__empty {
    height: pxToRem(144);
}

.keyvisual__big {
    height: calc(100vh - 144px);

    .keyvisual__big {
        height: 100%;
    }

    ul {
        height: 100%;
    }
}

/*** || Claim ***/

.claim {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    color: $color-white;
    text-align: center;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.08);
    }

    &__content {
        z-index: 10;
        width: auto;
        height: auto;
        @include getFigmaStyle('Desktop / Xxl / Bold / Default');
        text-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    }
}

@include media-breakpoint-down(md) {
    .keyvisual {
        margin-top: pxToRem(88);
        padding: 0 pxToRem(16);
    }

    .keyvisual__big {
        height: auto;
    }

    .claim {
        text-align: center;
    }
}