@import "@sx-core/src/scss/element/nav.scss";

.burger {
    position: relative;
    top: 4px;
    right: initial;
    margin-left: auto;
    z-index: 99999;

    .hamburger:hover {
        opacity: 1;
    }

    .hamburger-inner {
        height: 2px;
        background-color: $color-primary-800;

        &::before, &::after {
            height: 2px;
            background-color: $color-primary-800;
        }

        &::before {
            top: -8px;
        }

        &::after {
            bottom: -8px;
        }
    }

    .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
        background-color: $color-white;
    }
}

.plus {
    right: 0;
}

.header__navigation {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    width: auto;

    a {
        white-space: nowrap;
    }

    ul {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        //width: 100%;
        width: auto;
        margin: initial;

        ul {

            @include media(lg) {
                opacity: 0;
                pointer-events: none;
                display: block;
                position: absolute;
                top: 52px;
                transition: opacity .4s ease;
                &:before {
                    content: '';
                    position: absolute;
                    height: 10px;
                    width: 100%;
                    top: -10px;
                }
            }
        }
    }

    ul > li.level-0 {
        margin: 0 pxToRem(24);
        padding: pxToRem(5) 0;
        transition: all .3s ease;

        & > a::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            width: 0;
            height: 2px;
            background-color: $color-secondary-800;
            transition: all .3s ease;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                transform: translateY(-2px);

                & > a::after {
                    width: 100%;
                }
            }
        }
    }

    ul > li:hover ul {
        pointer-events: auto;
    }

    ul > li a {
        position: relative;
        display: flex;
        align-items: center;
        padding: pxToRem(6) 0;
        color: $color-primary-1200;
        @include getFigmaStyle('Desktop/MD/Medium/Default');
        transition: all .3s ease;
    }

    ul ul {
        left: 50%;
        transform: translateX(-50%);
    }

    li.level-0 > ul {
        background-color: $color-primary-800;
        border-radius: $border-radius;
    }

    li.level-1 > a {
        color: $color-white;
    }

    li.level-1:not(.has-children) {
        display: flex;
        align-items: center;
        padding: pxToRem(5) pxToRem(32);
    }
}

.header__booking-btn {
    white-space: nowrap;
}

.header__contact {
    display: none;
    color: $color-white;
    @include getFigmaStyle('Mobile / SM / Regular / Default');

    a {
        color: $color-white;
    }

    &.contact .contact__email span {
        text-decoration: none;
    }

    span {
        display: inline-block;
        //margin-bottom: pxToRem(8);
        @include getFigmaStyle('Mobile / MD / Bold / Default');
    }
}

@include media-breakpoint-down(xl) {
    .js-nav-open {
        overflow: hidden;
    }

    .burger {
        display: inline-block;
    }

    .header__contact {
        display: block;
    }

    .header__navigation {
        position: fixed;
        z-index: 550;
        top: 0;
        left: -100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        row-gap: pxToRem(36);
        width: 100%;
        min-height: 100vh;
        padding: pxToRem(100) pxToRem(20);
        background-color: $color-primary-800;
        -ms-overflow-style: scrollbar;
        overflow-style: scrollbar;
        transition: all .6s ease;

        ul {
            flex-direction: column;
            justify-content: flex-start;
            align-items: initial;
            height: auto;
            width: 100%;
        }

        ul ul {
            transform: initial;
        }

        ul > li.level-0 {
            display: block;
            height: auto;
            padding: initial;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: pxToRem(20) 0;
                color: $color-white;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $color-white;
                    opacity: 1;
                    transform: none;
                }
            }

            ul {
                background-color: $color-primary-800;

                 & > li:first-child {
                     padding: 0.3125rem 2rem;
                 }
            }
        }
    }

    .header__language {
        ul {
            flex-direction: row;
            align-items: center;
        }
    }

    .header__additions {
        flex-direction: column;
        row-gap: pxToRem(20);
        width: 100%;
    }

    .js-nav-open .header__navigation {
        left: 0;
    }
}

@include media-breakpoint-down(xl) {
    .burger {
        margin-left: initial;
    }
}

@include media-breakpoint-down(md) {
    .burger {
        margin-left: auto;
    }

    .js-nav-scrolled {
        .burger {
            margin-left: initial;
        }

        .header__booking-btn {
            margin-left: auto;
        }
    }
}