/*
  * Use only local fonts!!
  * Preload only woff2 in src/Templates/element/html/head.php
  * Preloader Example: <link rel="preload" href="/src/theme/default/font/***.woff2" as="font" type="font/woff2" crossorigin>
  * IMPORTANT!!!! All webfonts must be converted with https://transfonter.org/ and the option "Fix vertical metrics"
*/

@font-face {
    //https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools
    font-display: swap; //avoid showing invisible text
    //font-family: "???";
    //src: url("/src/theme/default/font/???");

}

/*
Copyright 2020 The Inter Project Authors (https://github.com/rsms/inter)

This Font Software is licensed under the SIL Open Font License, Version 1.1.
This license is copied below, and is also available with a FAQ at:
http://scripts.sil.org/OFL


-----------------------------------------------------------
SIL OPEN FONT LICENSE Version 1.1 - 26 February 2007
-----------------------------------------------------------
 */

@font-face {
    font-family: 'Inter';
    src: url('/dist/font/Inter-Light.woff2') format('woff2'),
    url('/dist/font/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/dist/font/Inter-SemiBold.woff2') format('woff2'),
    url('/dist/font/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/dist/font/Inter-Regular.woff2') format('woff2'),
    url('/dist/font/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/dist/font/Inter-Bold.woff2') format('woff2'),
    url('/dist/font/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/dist/font/Inter-Medium.woff2') format('woff2'),
    url('/dist/font/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Blacker Pro Display';
    src: url('/dist/font/BlackerProDisplay-Bold.woff2') format('woff2'),
    url('/dist/font/BlackerProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
