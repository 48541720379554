@import "@sx-core/src/scss/element/calendar.scss";

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    border-color: $color-primary-800;
    background: $color-primary-800;
}

.mbsc-ios.mbsc-range-day::after {
    background-color: $color-primary-200;
}

.mbsc-ios.mbsc-calendar-week-nr, .mbsc-ios.mbsc-calendar-today, .mbsc-ios.mbsc-button-flat {
    color: $color-primary-800;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button, .mbsc-ios.mbsc-range-control-value.active {
    color: $color-primary-800;
}

.mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
    color: $color-white;
    background-color: $color-primary-600;
}