@import "@sx-core/src/scss/element/teaser.scss";

.headline.teaser-item__headline, .headline.teaser-lg-item__headline {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    padding: pxToRem(4) pxToRem(12);
    margin-bottom: initial !important;
    @include getFigmaStyle('Desktop/MD/Medium/Default');
    color: $color-white;
    text-align: center;
    background-color: $color-secondary-800;
    border-radius: $border-radius;
    transition: all .3s ease;
    word-break: keep-all;
    overflow-wrap: break-word;
    hyphens: initial;
}

.teaser-item__image {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius;
    transition: all .3s ease;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .08);
        border-radius: $border-radius;
        opacity: 0;
        transition: all .3s ease;
    }

    .image__outer {
        height: 100%;
    }
}

.teaser-item {
    border-radius: $border-radius;
    transition: all .3s ease;

    @include media-breakpoint-up(md) {

        &:hover, &:focus {
            .teaser-item__image {
                box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.20);
            }

            .teaser-item__image::after {
                opacity: 1;
            }

            .teaser-item__headline {
                transform: translate(-50%, calc(-50% - 6px));
            }
        }
    }
}

/*** || Teaser Matrix ***/

.teasermatrix {
    position: relative;
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;

    &::before {
        content: '';
        position: absolute;
        top: 48px;
        left: 0;
        display: block;
        width: 100%;
        height: calc(100% - 96px);
        background-color: $color-secondary-400;
    }
}

.teasermatrix__inner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: pxToRem(48);
    grid-column-gap: pxToRem(48);

    .teasermatrix__item {
        position: relative;
    }

    .teaser-item {
        display: block;
        height: 100%;
    }

    .teaser-item__image, .image, img {
        height: 100%;
    }

    .teasermatrix__item:first-child {
        grid-row-start: 1;
        grid-row-end: span 2;
        grid-column-start: 1;
        grid-column-end: span 4;
    }

    .teasermatrix__item:nth-child(2) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 5;
        grid-column-end: span 4;
    }

    .teasermatrix__item:nth-child(3) {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 9;
        grid-column-end: span 4;
    }

    .teasermatrix__item:last-child {
        grid-row-start: 2;
        grid-row-end: span 1;
        grid-column-start: 5;
        grid-column-end: span 8;
    }
}

@include media-breakpoint-down(lg) {
    .teasermatrix {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;
    }

    .teaser__item:not(:last-child) {
        margin-bottom: pxToRem(28);
    }

    .teaser .teaser__item:nth-child(n + 4) {
        margin-top: initial;
    }

    //Matrix
    .teasermatrix__inner {
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;

        .teasermatrix__item:first-child {
            grid-row-start: 1;
            grid-row-end: span 1;
            grid-column-start: 1;
            grid-column-end: 1;
        }

        .teasermatrix__item:nth-child(2) {
            grid-row-start: 2;
            grid-row-end: span 1;
            grid-column-start: 1;
            grid-column-end: span 1;
        }

        .teasermatrix__item:nth-child(3) {
            grid-row-start: 3;
            grid-row-end: span 1;
            grid-column-start: 1;
            grid-column-end: span 1;
        }

        .teasermatrix__item:last-child {
            grid-row-start: 4;
            grid-row-end: span 1;
            grid-column-start: 1;
            grid-column-end: span 1;
        }
    }
}

/*** || Teaser LG ***/

.teaser-lg-item {
    &__image {
        position: relative;
        border-radius: $border-radius;
        overflow: hidden;
        transition: all .3s ease;
    }

    &__headline.headline {
        position: absolute;
        top: initial;
        bottom: pxToRem(48);
        transform: translatex(-50%);
    }

    &__body {
        margin-top: pxToRem(28);
        @include getFigmaStyle('Mobile/MD/Regular/Default');
        text-align: center;
    }

    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            .teaser-lg-item__image {
                box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.20);
            }

            .teaser-lg-item__headline {
                transform: translate(-50%, calc(-50% - 6px));
            }
        }
    }
}

/*** || Preis ***/

.teaser-lg-item, .teaser-item, .list-item {
    .price {
        position: absolute;
        top: pxToRem(16);
        right: pxToRem(16);
        z-index: 10;
        margin-top: initial;
        background-color: $color-primary-800;
        border-radius: 50%;

        &__inner {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            column-gap: initial;
            width: pxToRem(100);
            height: pxToRem(100);
            padding-bottom: pxToRem(8);
            color: $color-white;
        }

        &__prefix, &__suffix {
            @include getFigmaStyle('Desktop/XS/Regular/Default');
        }

        &__suffix {
            display: none;
        }

        &__price {
            display: block;
            @include getFigmaStyle('Desktop/LG/Bold/Default');
            white-space: nowrap;
        }
    }
}

@include media-breakpoint-down(md) {
    .teaser-lg-item, .teaser-item, .list-item {
        .price {
            &__inner {
                width: pxToRem(90);
                height: pxToRem(90);
            }
        }
    }
}