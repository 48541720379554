.bookingbox {
    padding: pxToRem(28) 0;
    background-color: $color-secondary-100;

    &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: pxToRem(4);
        row-gap: pxToRem(4);
    }

    &__button {
        min-width: pxToRem(300);
    }
}

.form--hidden {
    display: none;
}

@include media-breakpoint-down(md) {
    .bookingbox__button {
        width: 100%;
    }
}