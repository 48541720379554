@import "@sx-core/src/scss/element/intermediate.scss";

.intermediate {
    height: auto;
    padding: pxToRem(28);

    .image {
        border-radius: $border-radius;
        overflow: hidden;
    }
}

@include media-breakpoint-down(md) {
    .intermediate {
        padding: pxToRem(16);
    }
}