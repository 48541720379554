@import "@sx-core/src/scss/base/slider.base.scss";
@import "@sx-core/src/scss/element/slider.scss";

.splide.is-initialized, .splide {
    visibility: inherit !important;
}

/*** || Slider SM ***/

.slider {
    position: relative;
    margin-top: calc(#{$modulspacing-desktop-m} - 2.25rem);

    .splide {
        padding-bottom: $modulspacing-desktop-m;
    }

    &::before {
        content: '';
        position: absolute;
        top: 84px;
        left: 0;
        display: block;
        width: 100%;
        height: calc(100% - 48px - 36px);
        background-color: $color-primary-800;
    }

    &__item {
        padding-top: pxToRem(36);
    }
}

.splide__arrow {
    width: pxToRem(96);
    height: pxToRem(96);
    background-color: $color-black-alpha-500;
    border-radius: 50%;
    background-position: center;
    background-size: pxToRem(72) pxToRem(72);
    background-repeat: no-repeat;
    transition: all .3s ease;

    svg {
        display: none;
    }

    &--prev {
        background-image: inline-svg('arrow-long-left', $color-white);
    }

    &--next {
        background-image: inline-svg('arrow-long-right', $color-white);
    }
}

@include media-breakpoint-down(md) {
    .slider {
        margin-top: $modulspacing-mobile-m;

        &::before {
            top: 75px;
            height: calc(100% - 75px);
        }

        &__item {
            padding-top: initial;
        }

        .splide {
            padding-bottom: pxToRem(48);
        }
    }
}

/*** || Slider LG ***/

.slider-lg {
    margin-top: calc(#{$modulspacing-desktop-m} - 2.25rem);
    margin-bottom: $modulspacing-desktop-m;

    &__item {
        padding-top: pxToRem(36);
    }
}

@include media-breakpoint-down(md) {
    .slider-lg {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;

        &__item {
            padding-top: initial;
        }
    }
}