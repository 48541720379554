.gallery {
    padding: $modulspacing-desktop-m 0;
    background-color: $color-primary-800;

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: pxToRem(96);
        grid-row-gap: pxToRem(96);
    }

    &__item--image {
        position: relative;
        border-radius: $border-radius;
        overflow: hidden;
        transition: all .3s ease;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .08);
            border-radius: $border-radius;
            opacity: 0;
            transition: all .3s ease;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: pxToRem(28);
            right: pxToRem(28);
            z-index: 10;
            display: inline-block;
            width: pxToRem(36);
            height: pxToRem(36);
            background-color: $color-primary-800;
            background-image: inline-svg('plus', $color-white);
            background-position: center;
            background-size: pxToRem(16) pxToRem(16);
            background-repeat: no-repeat;
            border-radius: 50%;
            opacity: 0;
            transition: all .3s ease;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.20);

                &::before, &::after {
                    opacity: 1;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .gallery {
        padding: $modulspacing-mobile-s 0;
        &__grid {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: pxToRem(16);
        }
    }
}