.factbox {
    margin-top: $modulspacing-desktop-s;
    margin-bottom: $modulspacing-desktop-m;

    &__inner {
        border: 4px solid $color-primary-800;
        border-radius: $border-radius;
    }

    &__header {
        padding: pxToRem(20) 0 pxToRem(24);
        color: $color-white;
        @include getFigmaStyle('Desktop/H3/Bold/Default');
        text-align: center;
        background-color: $color-primary-800;
    }

    &__content {
        padding: pxToRem(28) pxToRem(48) pxToRem(36);
    }
}

ul.facts {
    display: flex;
    flex-wrap: wrap;
    row-gap: pxToRem(16);

    & > li {
        flex-basis: 50%;
        @include getFigmaStyle('Desktop/LG/Regular/Default');
    }
}

@include media-breakpoint-down(md) {
    .factbox {
        margin-top: $modulspacing-mobile-s;
        margin-bottom: $modulspacing-mobile-m;

        &__content {
            padding: pxToRem(24) pxToRem(16) pxToRem(28);
        }
    }

    ul.facts {
        & > li {
            flex-basis: 100%;
            &:not(:first-child) {
                margin-top: pxToRem(16);
            }
        }
    }
}