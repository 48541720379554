@import "@sx-core/src/scss/base/button.base.scss";
@import "@sx-core/src/scss/element/button.scss";

$btn-font-family: $font-family-primary;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping
$btn-font-weight: $font-weight-bold;

$btn-border-radius: 12px;
$btn-border-width: 1px;
$btn-border-style: solid;


$btn-border-width: 4px;
$btn-disabled-opacity: 1;
$btn-box-shadow: null;
$btn-focus-width: $btn-border-width;
$btn-focus-box-shadow: 0 0 0 $btn-focus-width $color-white;
$btn-active-box-shadow: null;


$scale-factor-sm: 0.5;
$scale-factor-lg: 1.67;

$font-size-sm: pxToRem(18);
$font-size-md: pxToRem(18);
$font-size-lg: pxToRem(18);


/*default*/

$btn-padding-x-1: pxToRem(36); //rechts
$btn-padding-x-2: pxToRem(36); //links
$btn-padding-y-1: pxToRem(7); //oben
$btn-padding-y-2: pxToRem(7); //unten
$btn-font-size: $font-size-md;
$btn-line-height: 1.2;
$btn-border-radius: $btn-border-radius;

/*md*/
$btn-padding-x-1-md: $btn-padding-x-1;
$btn-padding-x-2-md: $btn-padding-x-2;
$btn-padding-y-1-md: $btn-padding-y-1;
$btn-padding-y-2-md: $btn-padding-y-2;
$btn-font-size-md: $font-size-md;
$btn-line-height-md: $btn-line-height;
$btn-border-radius-md: $btn-border-radius;

/*sm*/
$btn-padding-x-1-sm: pxToRem(12);
$btn-padding-x-2-sm: pxToRem(12);
$btn-padding-y-1-sm: pxToRem(1);
$btn-padding-y-2-sm: pxToRem(1);
$btn-font-size-sm: $font-size-sm;
$btn-line-height-sm: 1.2;
$btn-border-radius-sm: $btn-border-radius;

/*lg*/
$btn-padding-x-1-lg: pxToRem(36);
$btn-padding-x-2-lg: pxToRem(36);
$btn-padding-y-1-lg: pxToRem(15);
$btn-padding-y-2-lg: pxToRem(15);
$btn-font-size-lg: $font-size-lg;
$btn-line-height-lg: 1.2;
$btn-border-radius-lg: $btn-border-radius;


/*ICON*/

$btn-icon-size: 24px;


@mixin button-variant(
    $background-color,
    $border-color,


    $color: text-contrast($background-color),

    $hover-background-color: darken($background-color,30%),
    $hover-border-color: darken($background-color,30%),
    $hover-color: text-contrast($hover-background-color),

    $active-background-color: lighten($background-color,30%),
    $active-border-color: lighten($background-color,30%),
    $active-color: text-contrast($active-background-color),

    $disabled-background-color: $color-grey-500,
    $disabled-border-color: $color-grey-500,
    $disabled-color: text-contrast($color-white),

    $border-width: $btn-border-width,
    $border-style: $btn-border-style

) {


    color: $color;
    background-color: $background-color;
    border-color: $border-color;
    border-width: $border-width;
    border-style: $border-style;
    //box-shadow: $shadow;

    &:hover {
        color: $hover-color;
        background-color: $hover-background-color;
        border-color: $hover-border-color;
    }

    &:focus {
        color: $hover-color;
        background-color: $hover-background-color;
        border-color: $hover-border-color;
        outline-color: $hover-border-color;
    }

    &:active {
        color: $active-color;
        background-color: $active-background-color;
        border-color: $active-border-color;
        outline-color: $active-border-color;
    }

    &:disabled,
    &.disabled {
        color: $disabled-color;
        background-color: $disabled-background-color;
        border-color: $disabled-border-color;
    }
}

@mixin button-size($padding-y-1, $padding-y-2, $padding-x-1, $padding-x-2, $font-size, $border-radius, $line-height: null) {
    //--#{$boosted-variable-prefix}icon-spacing: #{$icon-spacing}; : used for icons
    //padding: ($padding-y - pxToRem(1px)) $padding-x ($padding-y + pxToRem(1px));
    padding: $padding-y-1 $padding-x-1 $padding-y-2 $padding-x-2;
    font-size: $font-size;
    line-height: $line-height;
    // Manually declare to provide an override to the browser default
    border-radius: $border-radius

    //TODO Icon Size & Paddings & Margins
}


@mixin button-icon(
    $icon: 'arrow-right',
    $pseudo: after,

    $width: 24px,
    $height: $width,

    $icon-width: 24px,
    $icon-height: $icon-width,

    $margin-left: 50px,
    $margin-right: 6px,

    $border-radius: 50%,
    $border-color: $color-black,
    $border-width: $btn-border-width,

    $color: $color-black,
    $fillcolor: transparent,
	$strokecolor: $color,

) {

    display: flex;
    align-items: center;

    @if $pseudo == before {
        padding-left: $margin-left;
    }

    @if $pseudo == after {
        padding-right: $margin-right;
    }

    &:#{$pseudo} {
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: $border-radius;
        width: $width;
        height: $height;

        background: inline-svg($icon, $color, $fillcolor, $strokecolor);
        background-size: $icon-width $icon-height;
        background-repeat: no-repeat;
        background-position: center center;

        @if $pseudo == before {
            margin-right: $margin-right;
        }

        @if $pseudo == after {
            margin-left: $margin-left;
        }
    }


    &:hover {
        //TODO
    }

    &:focus {
        //TODO
    }

    &:active {
        //TODO
    }

    &:disabled,
    &.disabled {
        //TODO
    }


}


a.button, .button {
    display: inline-flex;
    justify-content: center;
    @include getFigmaStyle('Desktop/MD/Medium/Default');
    transition: all .3s ease;
}


/* Button sizes*/

.button--sm {
    @include button-size($btn-padding-y-1-sm, $btn-padding-y-2-sm, $btn-padding-x-1-sm, $btn-padding-x-2-sm, $btn-font-size-sm, $btn-border-radius-sm, $btn-line-height-sm);
}

.button--md {
    @include button-size($btn-padding-y-1-md, $btn-padding-y-2-md, $btn-padding-x-1-md, $btn-padding-x-2-md, $btn-font-size-md, $btn-border-radius-md, $btn-line-height-md);
}

.button--lg {
    @include button-size($btn-padding-y-1-lg, $btn-padding-y-2-lg, $btn-padding-x-1-lg, $btn-padding-x-2-lg, $btn-font-size-lg, $btn-border-radius-lg, $btn-line-height-lg);
}


/*Buttons bauen */

.button--primary {
    @include button-variant($color-secondary-800, $color-secondary-800, $color-white,
    $hover-background-color: $color-primary-800,
    $hover-border-color: $color-primary-800,
    $hover-color: $color-white,

    $active-background-color: $color-primary-800,
    $active-border-color: $color-primary-800,
    $active-color: $color-white);
}

.button--secondary {
    @include button-variant(transparent, $color-secondary-800, $color-secondary-800,
    $hover-background-color: $color-primary-800,
    $hover-border-color: $color-primary-800,
    $hover-color: $color-white,

    $active-background-color: $color-primary-800,
    $active-border-color: $color-primary-800,
    $active-color: $color-white);
}

.button--primary.button--inverted {
    @include button-variant($color-secondary-800, $color-secondary-800, $color-white,
    $hover-background-color: $color-white,
    $hover-border-color: $color-white,
    $hover-color: $color-primary-800,

    $active-background-color: $color-white,
    $active-border-color: $color-white,
    $active-color: $color-primary-800);
}

/* Outline example */
//.button--primary.button--outline {
//    @include button-variant(transparent, $color-primary-800, $color-primary-800,
//    $hover-background-color: $color-primary-800,
//    $hover-border-color: $color-primary-800,
//    $hover-color: $color-secondary-800,
//
//    $active-background-color: $color-primary-800,
//    $active-border-color: $color-primary-800,
//    $active-color: $color-secondary-800);
//}

.button--secondary.button--inverted {
    @include button-variant(transparent, $color-secondary-800, $color-secondary-800,
    $hover-background-color: $color-white,
    $hover-border-color: $color-white,
    $hover-color: $color-primary-800,

    $active-background-color: $color-white,
    $active-border-color: $color-white,
    $active-color: $color-primary-800);
}


//.button--primary.button--bg {
//    @include button-variant($color-primary-800, $color-primary-800, $color-white,
//    $hover-background-color: $color-secondary-800,
//    $hover-border-color: $color-secondary-800,
//    $hover-color: $color-white,
//
//    $active-background-color: $color-secondary-800,
//    $active-border-color: $color-secondary-800,
//    $active-color: $color-white);
//}
//
//.button--secondary.button--bg {
//    @include button-variant($color-secondary-800, $color-secondary-800, $color-primary-800,
//    $hover-background-color: $color-white,
//    $hover-border-color: $color-white,
//    $hover-color: $color-primary-800,
//
//    $active-background-color: $color-white,
//    $active-border-color: $color-white,
//    $active-color: $color-primary-800);
//}


//.button--primary.button--outline.button--bg {
//    @include button-variant(transparent, $color-primary-800, $color-primary-800,
//    $hover-background-color: $color-secondary-800,
//    $hover-border-color: $color-secondary-800,
//    $hover-color: $color-white,
//
//    $active-background-color: $color-secondary-800,
//    $active-border-color: $color-secondary-800,
//    $active-color: $color-white);
//}

.button--default, .button {

}

.button--icon {
    //@include button-icon();
}

.button--default.button--icon, .button.button--icon {

}

.button--link {

}

.button--link.button--icon {

}