.booking-bar {
    position: absolute;
    bottom: pxToRem(64);
    left: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    height: auto;

    &__inner {
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        column-gap: pxToRem(4);
        min-height: pxToRem(80);
        width: auto;
    }

    input, .chosen-container .chosen-single {
        &:hover, &:focus {
            box-shadow: none;
            border-color: $color-white;
        }
    }

    .chosen-container.chosen-container-active .chosen-single, .chosen-container.chosen-with-drop .chosen-single, .chosen-container .chosen-drop {
        border-color: $color-white;
    }

    .chosen-container .chosen-drop {
        border: 2px solid $color-primary-200;
        border-top: none;
    }

    &__input {
        flex: 1 1 auto;
        height: pxToRem(64);
        //width: auto;
        max-width: pxToRem(300);
        transition: all .3s ease;

        .daterange, .chosen-container, .chosen-single, .booking-bar__booking-button {
            //display: flex;
            //flex: 1;
            height: 100%;
            //width: 100%;
        }

        .input {
            height: 100%;
            width: 100%;
        }

        &--guests {
            max-width: pxToRem(150);
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                height: 100%;
            }
        }
    }
}

.booking-bar__booking-button--mobile.button {
    display: none;
    width: 100%;
}

input.sx-daterange {
    padding-left: pxToRem(48);
    background-image: inline-svg('calendar', $color-primary-800, transparent), inline-svg('angle-down-filled', $color-primary-800);
    background-position: left pxToRem(12) top 50%, right pxToRem(12) top 50%;
    background-size: pxToRem(24) pxToRem(24);
    background-repeat: no-repeat;
}

@include media-breakpoint-down(md) {
    .booking-bar {
        bottom: pxToRem(16);
        padding: 0 pxToRem(16);

        &__inner {
            min-height: auto;
        }

        &__input {
            display: none;
        }

        &__booking-button--mobile.button {
            display: inline-flex;
        }
    }
}