@mixin SxEditor {

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, table {
        //font-weight: 400;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
        // margin-bottom: pxToRem(8);
        // line-height: 1.2;
    }

    h5, .h5, h6, .h6 {
        //margin-bottom: pxToRem(16);
        //line-height: 1.3;
    }

    h1, .h1, h2, .h2 {
        @include getFigmaStyle('Desktop/H1/Bold/Default');
        margin-bottom: pxToRem(36);
    }

    h3, .h3 {
        @include getFigmaStyle('Desktop/H3/Bold/Default');
        margin-bottom: pxToRem(36);
    }

    h4, .h4 {
        @include getFigmaStyle('Desktop/H4/Bold/Default');
        margin-bottom: pxToRem(36);
    }

    h5, .h5 {
        @include getFigmaStyle('Desktop/H5/Bold/Default');
        margin-bottom: pxToRem(36);
    }

    h6, .h6 {
        @include getFigmaStyle('Desktop/H6/Bold/Default');
        margin-bottom: pxToRem(36);
    }

    p {
        @include getFigmaStyle('Desktop/MD/Regular/Default');
        color: $color-grey-1200;
        margin-bottom: pxToRem(20);

        & + p {
            margin-bottom: pxToRem(12);
        }
    }

    ul, ol, table {
        margin-top: pxToRem(36);
        margin-bottom: pxToRem(36);
    }

    hr {
        // border-top: 1px solid $color-dark;
        width: pxToRem(60);
        height: pxToRem(3);
        margin: pxToRem(28) auto;
        background-color: $color-secondary-800;
    }

    b, strong {
        font-weight: 700;
    }

    a {
        //text-decoration: underline;
    }

    ol {
        list-style-type: decimal;
        padding-left: pxToRem(18);
        column-count: 1;
        column-gap: pxToRem(28);
        text-align: left;
        margin: pxToRem(36) 0;
    }

    ol li {
        position: relative;
        padding-left: pxToRem(4);
        font-weight: 600;
        font-size: pxToRem(17);
        margin-bottom: pxToRem(16);
    }

    ul {
        padding-left: pxToRem(19);
        list-style-type: disc;
        column-count: 1;
        column-gap: pxToRem(28);
        text-align: left;
        margin: pxToRem(36) 0;
    }

    ul li {
        padding-left: pxToRem(4);
        position: relative;
        font-weight: 600;
        font-size: pxToRem(17);
        margin-bottom: pxToRem(16);
    }

    //ul li:before {
    //    content: '\00a0';
    //    position: absolute;
    //    top: 0;
    //    left: -1.2rem;
    //    display: block;
    //    width: 1.2rem;
    //    height: 1.6rem;
    //    background-image: inline-svg('check', $color-grey-60);
    //    background-repeat: no-repeat;
    //    background-size: 0.8rem 0.8rem;
    //    background-position: 0 center;
    //}

    ul li::marker,
    ol li::marker {
        font-size: pxToRem(16);
        color: $color-primary;
    }

    ol ol, ul ul, ol ul, ul ol {
        margin-bottom: 0;
    }

    table {
        text-align: left;
        border: 1px solid $color-grey-200;
        width: 100%;
    }

    table th,
    table td,
    table thead th,
    table tbody td {
        text-align: left;
        background: transparent;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid $color-grey-200;
    }

    thead tr:last-child {
        border-bottom: 2px solid $color-black;
    }

    th, td {
        padding: 8px 12px;
    }

    th:not(:last-child),
    td:not(:last-child) {
        border-right: 1px solid $color-grey-100;
    }

    em, i {
        font-style: italic;
    }
}
