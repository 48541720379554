.blog {
    padding-top: $modulspacing-desktop-m;
    padding-bottom: $modulspacing-desktop-m;
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;
    background-color: $color-secondary-100;

    &__item {
        background-color: $color-white;
        border-radius: $border-radius;
        &:not(:last-child) {
            margin-bottom: pxToRem(48);
        }
    }

    &__headline.headline {
        position: relative;
        padding-bottom: pxToRem(16);
        margin-bottom: pxToRem(16);
        @include getFigmaStyle('Desktop/H4/Bold/Default');

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;
            width: 60px;
            height: 3px;
            background-color: $color-primary-800;
        }
    }

    &__content {
        *:last-child {
            margin-bottom: initial;
        }
    }
}

.blog-item {
    &__image {
        border-radius: $border-radius;
        overflow: hidden;
    }

    &__body {
        padding: pxToRem(48) pxToRem(28);
    }
}

.blog__item--mobile {
    display: none;
}

@include media-breakpoint-down(lg) {
    .column1, .column2, .column3 {
        display: none;
    }

    .blog__item--mobile {
        display: block;
    }
}