@import "@sx-core/src/scss/element/accordion.scss";

.accordion__item:not(:first-child) {
    margin-top: pxToRem(8);
}

.accordion-item {
    &__header {
        padding: pxToRem(28) pxToRem(48) pxToRem(28) 0;
        @include getFigmaStyle('Desktop/H5/Medium/Default');

        &::after {
            top: calc(50% - 18px);
            width: pxToRem(36);
            height: pxToRem(36);
            background-color: $color-primary-800;
            background-image: inline-svg('plus', $color-white);
            background-position: center;
            background-size: 16px 16px;
            background-repeat: no-repeat;
            border-radius: 50%;
            transition: all .3s ease;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                &::after {
                    background-color: $color-primary-1000;
                }
            }
        }
    }

    &__body {
        padding-bottom: pxToRem(36);
    }
}

.accordion__item.uk-open .accordion-item__header::after {
    background-image: inline-svg('minus', $color-white);
    transform: none;
}

.accordion__item.uk-open .accordion-item__header {
    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            &::after {
                background-color: $color-primary-1000;
            }
        }
    }
}