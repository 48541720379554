.asp {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;
    padding-top: pxToRem(48);
    padding-bottom: pxToRem(48);
    background-color: $color-primary-800;

    &__item {
        padding: 0 pxToRem(48);
    }
}

.bg-coloured + .asp, .gallery + .asp {
    margin-top: initial;
}

.asp-item {
    display: flex;
    align-items: center;
    color: $color-white;
    border: 4px solid $color-secondary-800;
    border-radius: 320px $border-radius $border-radius 320px;
    border-left: none;

    p, h3, .headline.headline--topline {
        color: $color-white;
    }

    &__image {
        min-width: 33.3333333333%;
        .image {
            border-radius: 50%;
            overflow: hidden;
        }
    }

    &__body {
        //border: 4px solid $color-secondary-800;
        padding: pxToRem(28) pxToRem(36) pxToRem(28) pxToRem(48);
        //padding-left: pxToRem(48);
    }

    &__headline.headline--topline {
        margin-bottom: pxToRem(8);
        color: $color-white;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }

    .asp__phone, .asp__email {
        display: inline-block;
        color: $color-white;
        @include getFigmaStyle('Desktop/H5/Bold/Default');
    }

    .asp__email {
        &::before {
            content: '| ';
        }
    }

    &__content {
        margin-top: pxToRem(28);
    }

    .button {
        //margin-top: pxToRem(36);
    }
}

h3.asp-item__headline {
    margin-bottom: pxToRem(28);
    @include getFigmaStyle('Desktop/H4/Bold/Default');
}

@include media-breakpoint-down(xl) {
    .asp {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;

        &__container {
            justify-content: center;
        }

        &__item {
            padding: initial;
            max-width: 350px;
        }
    }

    .asp-item {
        flex-direction: column;
        border-radius: 320px 320px $border-radius $border-radius;
        border-left: 4px solid $color-secondary-800;
        border-top: none;

        &__body {
            padding: pxToRem(28) pxToRem(8);
            text-align: center;
        }

        .asp__phone {
            margin-bottom: pxToRem(16);
        }

        .asp__email {
            &::before {
                content: none;
            }
        }
    }
}