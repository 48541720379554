//{RM} @import "@sx-core/src/scss/base/button.base.scss";

.button, .button--primary, .button--secondary {
    border: 0;
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background: $color-primary;
    color: $color-white;

    &:hover {
    }

    &:active {
    }

    &:focus {
    }

    &:disabled, &.disabled {
    }
}

.button--secondary {
    background: $color-secondary;

    &:hover {
    }

    &:active {
    }

    &:focus {
    }

    &:disabled, &.disabled {
    }
}


.button-group {
    display: flex;

    & > .button {
        flex: 0 0 auto;
    }
}


.cta {
    display: flex;
    column-gap: pxToRem(5);
}