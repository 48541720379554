@import "@sx-core/src/scss/element/list.scss";

.list {
    position: relative;
    margin-top: $modulspacing-desktop-m;
    padding-bottom: $modulspacing-desktop-m;

    &::before {
        content: '';
        position: absolute;
        top: 48px;
        left: 0;
        display: block;
        width: 100%;
        height: calc(100% - 48px);
        background-color: $color-primary-800;
    }
}

.list__item:not(:first-child) {
    margin-top: pxToRem(48);
}

.list-item {
    background-color: $color-secondary-100;
    border-radius: $border-radius;
    transition: all .3s ease;

    &__image {
        position: relative;
    }

    &__image .image {
        position: relative;
        border-radius: $border-radius;
        overflow: hidden;
    }

    h3.list-item__headline {
        color: $color-primary-1200;
        @include getFigmaStyle('Desktop/H4/Bold/Default');
    }

    &__body {
        padding: pxToRem(48);
    }

    &__teasertext {
        color: $color-primary-1200;
    }

    .readmore {
        margin-top: pxToRem(36);
    }

    @include media-breakpoint-up(lg) {
        &:hover, &:focus {
            box-shadow: 0 4px 36px 0 rgba($color-black, .2);
        }
    }
}

@include media-breakpoint-down(md) {
    .list {
        padding-bottom: $modulspacing-mobile-m;
        margin-top: $modulspacing-mobile-m;

        &__item:not(:first-child) {
            margin-top: pxToRem(16);
        }
    }

    .list-item {
        &__headline.headline {
            margin-bottom: pxToRem(12);
        }

        &__body {
            padding: pxToRem(28) pxToRem(12);
        }

        .readmore {
            margin-top: pxToRem(24);
        }
    }
}