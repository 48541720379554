.benefits {
    margin-top: $modulspacing-desktop-s;
    margin-bottom: $modulspacing-desktop-m;

    &__container {
        justify-content: center;
        column-gap: pxToRem(48);
        row-gap: pxToRem(48);
    }
}

.benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: pxToRem(190);

    &__image {
        width: pxToRem(120);
        height: pxToRem(120);
        background-color: $color-secondary-800;
        border-radius: 50%;
    }

    &__content {
        padding: pxToRem(8) pxToRem(12) pxToRem(6);
        color: $color-white;
        @include getFigmaStyle('Desktop/H6/Bold/Default');
        text-align: center;
        background-color: $color-primary-800;
        border-radius: $border-radius;
        transform: translateY(-6px);
    }
}

@include media-breakpoint-down(md) {
    .benefits {
        margin-top: $modulspacing-mobile-s;
        margin-bottom: $modulspacing-mobile-m;

        &__container {
            justify-content: center;
            column-gap: pxToRem(16);
            row-gap: pxToRem(16);
        }
    }
}