@import "@sx-core/src/scss/element/footer.scss";

.footer {
    height: auto;
    padding: pxToRem(64) 0;
    color: $color-white;
    background-color: $color-primary-800;

    a {
        color: $color-white;
    }

    & > .container, & > .container > .grid {
        height: auto;
    }

    &__top, &__bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

    &__top {
        row-gap: pxToRem(48);
        margin-bottom: pxToRem(48);
    }

    &-top__upper {
        display: flex;
        justify-content: space-between;
    }

    &__logo {
        max-width: pxToRem(256);
    }

    &__navigation {
        display: flex;
        align-items: center;

        & > ul {
            display: flex;
            justify-content: flex-end;
        }

        & > ul > li {
            padding: 0 pxToRem(24);
        }

        & > ul > li a {
            position: relative;
            display: inline-block;
            padding: pxToRem(6) 0;
            color: $color-white;
            font-size: pxToRem(17);
            @include getFigmaStyle('Desktop/MD/Medium/Default');
            transition: all .3s ease;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                display: inline-block;
                width: 0;
                height: 2px;
                background-color: $color-secondary-800;
                transition: all .3s ease;
            }

            @include media-breakpoint-up(md) {
                &:hover, &:focus {
                    color: $color-white;
                    transform: translateY(-2px);
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__bottom {
        align-items: flex-end;
        flex-wrap: wrap;
        padding-top: pxToRem(48);
        column-gap: pxToRem(48);
        row-gap: pxToRem(36);
        border-top: 1px solid $color-white;
    }

    &__cookies {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: auto;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: all .3s ease;

        &::before {
            content: '';
            display: inline-block;
            width: 36px;
            height: 36px;
            margin-right: pxToRem(12);
            background-image: inline-svg('cookie', $color-white, transparent);
            background-position: center;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            border: 2px solid $color-secondary-800;
            border-radius: 50%;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus, &:active {
                text-decoration-color: $color-white;
            }
        }

        &.footer__cookies--mobile {
            color: $color-white;
            text-decoration: none;

            &::before {
                background-image: inline-svg('cookie', $color-white, transparent);
                border-color: $color-white;
            }
        }
    }

    &__socials {
        display: flex;
        column-gap: pxToRem(28);
        //margin-left: pxToRem(48);

        a {
            width: pxToRem(36);
            max-width: pxToRem(36);
        }
    }

    &__logo-container {
        display: flex;
        align-items: center;
        column-gap: pxToRem(12);

        img {
            height: 80px;
            width: auto;
        }
    }
}

.contact {
    @include getFigmaStyle('Desktop/SM/Bold/Default');

    a {
        display: block;
    }

    span {
        display: inline-block;
        margin-left: pxToRem(8);
        @include getFigmaStyle('Desktop/SM/Regular/Default');
        font-size: pxToRem(16);
    }

    .contact__title {
        @include getFigmaStyle('Mobile/H5/Medium/Default');
        margin-bottom: pxToRem(12);
    }

    .contact__address {
        margin-bottom: pxToRem(8);
        @include getFigmaStyle('Mobile/SM/Regular/Default');
        font-size: pxToRem(16);
    }

    .contact__phone {
        position: relative;
    }

    .contact__email {
        position: relative;
    }
}

.footer__contact {
    display: flex;
    flex-wrap: wrap;
    column-gap: pxToRem(48);

    .contact__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .contact__address {
        margin-bottom: initial;
    }

    .contact__address, .contact__phone, .contact__email {
        color: $color-grey-400;
        @include getFigmaStyle('Desktop/SM/Regular/Default');

        span {
            display: inline-block;
            margin-right: pxToRem(20);
            margin-left: 0;
            color: $color-white;
        }
    }
}

@include media-breakpoint-down(xl) {
    .footer {
        &__bottom {
            flex-direction: column;
            align-items: flex-start;
        }

        &__logo-container {
            order: 2;
        }

        &__contact {
            order: 1;
        }
    }
}

@include media-breakpoint-down(md) {
    .footer {
        padding: pxToRem(36) pxToRem(8);

        &__top {
            flex-direction: column;
            row-gap: pxToRem(36);
            margin-bottom: pxToRem(36);
        }

        &-top__upper {
            flex-direction: column;
        }

        &__logo {
             margin: 0 auto;
        }

        &__logo:not(.footer__logo-container .footer__logo) {
            order: 2;
        }

        &__navigation {
            order: 1;
        }

        &__navigation {
            padding-bottom: pxToRem(28);
            margin-bottom: pxToRem(28);
            border-bottom: 1px solid $color-secondary-500;

            & > ul {
                flex-wrap: wrap;
                justify-content: center;
                row-gap: pxToRem(8);

                & > li {
                    padding: 0 pxToRem(18);
                }
            }
        }

        &__bottom {
            flex-direction: column;
            align-items: flex-start;
            row-gap: pxToRem(36);
            padding-top: pxToRem(36);
        }

        &__cookies {
            margin-left: initial;
            margin-right: auto;
        }

        &__socials {
            flex-wrap: wrap;
            margin-left: auto;
        }
    }

    .contact {
        a:not(:first-child) {
            margin-top: pxToRem(8);
        }

        .contact__address {
            margin-bottom: pxToRem(12);
        }

        .contact__email {
            span {
                text-decoration: initial;
            }
        }
    }

    .footer__contact {
        a:not(:first-child) {
            margin-top: 0;
        }

        .contact__address {
            margin-bottom: 0;
        }
    }
}